/**
 * 随机生成uuid
 * @return string 生成的uuid
 */
export function randomUUID() {
  let chats = "0123456789abcdef";
  return randomString(32, chats);
}

/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
export function randomString(length, chats) {
  if (!length) length = 1;
  if (!chats) chats = "0123456789qwertyuioplkjhgfdsazxcvbnm";
  let str = "";
  for (let i = 0; i < length; i++) {
    let num = randomNumber(0, chats.length - 1);
    str += chats[num];
  }
  return str;
}

/**
 * 随机生成数字
 *
 * 示例：生成长度为 12 的随机数：randomNumber(12)
 * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
 *
 * @param1 最小值 | 长度
 * @param2 最大值
 * @return int 生成后的数字
 */
export function randomNumber() {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  if (arguments.length === 1) {
    let [length] = arguments;
    // 生成指定长度的随机数字，首位一定不是 0
    let nums = [...Array(length).keys()].map((i) =>
      i > 0 ? random(0, 9) : random(1, 9)
    );
    return parseInt(nums.join(""));
  } else if (arguments.length >= 2) {
    let [min, max] = arguments;
    return random(min, max);
  } else {
    return Number.NaN;
  }
}

/**
 * 支付跳转 截取url 参数  示例 https://xxx.xx.xx.x:xxxx/?ssl_amount=25.00&ssl_card_short_description=VISA&ssl_get_token=Y&ssl_token_response=SUCCESS&ssl_avs_address=1600+Amphitheatre+Parkway&ssl_state=CA&ssl_token=4034762327081111&ssl_last_name=Name&ssl_approval_code=923249&ssl_avs_zip=94043&ssl_exp_date=1226&ssl_country=USA&ssl_city=Mountain+View&ssl_first_name=Card+Holder&ssl_txn_id=170624O2C-C76D8710-9F57-4C16-A804-65856990B3C0&ssl_transaction_type=SALE&ssl_result=0&ssl_result_message=APPROVAL&ssl_card_number=41**********1111&ssl_avs_response=N&ssl_txn_time=06%2F17%2F2024+04%3A54%3A58+AM#/
 * @return Object: { key: value }
 */
export function getPramas(url) {
  let paramsObj = {};
  if (url.indexOf("ssl_result_message") === -1) return paramsObj;
  let replaceUrl = url.split("#")[0];
  if (!replaceUrl) return paramsObj;
  let pramsStr = replaceUrl.split("?")[1];
  if (!pramsStr) return paramsObj;
  let pramasList = pramsStr.split("&");
  if (!pramasList || !pramasList.length) return paramsObj;
  for (let i = 0; i < pramasList.length; i++) {
    let item = pramasList[i].split("=");
    let key = item[0];
    let value = item[1];
    paramsObj[key] = value;
  }

  paramsObj.fullPath = pramsStr;
  return paramsObj;
}
