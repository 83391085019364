<template>
  <div>
    <van-popup v-model="showPopup" round lock-scroll position="bottom" z-index="1001">
      <div class="header row-between">
        <div class="omit flex1">{{ selectFood.name }}</div>
        <div @click="close">
          <van-icon name="cross" size="20" />
        </div>
      </div>
      <div class="hint center">Please select From/To dates and times</div>

      <div class="from center">
        <div class="label">From</div>
        <!-- <div class="inputBox center" @click="chooseDate('fromDate')">
          <div class="icon center">
            <van-icon name="notes-o" size="16" color="#a4a4a4"></van-icon>
          </div>
          <div :class="[!dataForm.fromDate ? 'noValue' : 'value']">
            {{ formatDate(dataForm.fromDate) }}
          </div>
        </div> -->
        <div class="inputBox center" style="margin-left: 20px" @click="chooseTime('fromTime')">
          <div class="icon center">
            <van-icon name="underway-o" size="16" color="#a4a4a4"></van-icon>
          </div>
          <div :class="[!dataForm.fromTime ? 'noValue' : 'value']">
            <div>{{ dataForm.fromDate }}</div>
            <div> {{ dataForm.fromTime }}</div>
          </div>
        </div>
      </div>

      <div class="from center" style="margin-top: 30px">
        <div class="label">To</div>
        <!-- <div class="inputBox center" @click="chooseDate('toDate')">
          <div class="icon center">
            <van-icon name="notes-o" size="16" color="#a4a4a4"></van-icon>
          </div>
          <div :class="[!dataForm.toDate ? 'noValue' : 'value']">
            <div>{{ dataForm.toDate }}</div>
            <div> {{ dataForm.toTime || "select" }}</div>
          </div>
        </div> -->
        <div class="inputBox center" style="margin-left: 20px" @click="chooseTime('toTime')">
          <div class="icon center">
            <van-icon name="underway-o" size="16" color="#a4a4a4"></van-icon>
          </div>
          <div :class="[!dataForm.toTime ? 'noValue' : 'value']">
            <!-- {{ dataForm.toTime || "select" }} -->
            <div>{{ dataForm.toDate }}</div>
            <div> {{ dataForm.toTime || "Select Date/Time " }}</div>
          </div>
        </div>
      </div>

      <div class="btn-group center">
        <van-button class="btn-skip" style="height: 30pt" plain @click="submit">Next</van-button>
      </div>
    </van-popup>

    <!--  日期选择  -->
    <!-- <van-calendar v-model="showDate" :color="primaryColor" :show-title="false" :min-date="minDate"
      @confirm="dateConfirm" :default-date="defaultDate" /> -->

    <!-- 时间选择 -->
    <van-popup v-model="showTime" position="bottom">
      <van-picker v-if="showTime" :columns="fromTimeColumns" show-toolbar @confirm="timeConfirm" @cancel="fromTimePikerCancel" />
    </van-popup>
    <van-popup v-model="showToTime" position="bottom">
      <van-picker v-if="showToTime" :columns="toTimeColumns" show-toolbar @confirm="timeConfirm" @cancel="toTimePikerCancel" />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import { postAction } from "@/api/manage";
export default {
  data() {
    return {
      fromTimeColumns: [],
      toTimeColumns: [],
      showPopup: false,
      dataForm: {
        fromDate: "Today",
        fromTime: "Now",
        toDate: "",
        toTime: "",
      },
      showDate: false,
      showTime: false,
      showToTime: false,
      chooseDateType: "",
      chooseTimeType: "",
      selectFood: {}, // 选择商品信息
      isEdit: false,
      timeStamp: [],
    };
  },
  computed: {
    ...mapGetters({
      shoppingFood: "foods", //购物车中的餐品
      primaryColor: "primaryColor",
    }),
    minDate() {
      return new Date("2024-01-01");
    },
    defaultDate() {
      return new Date(
        moment(new Date())
          .tz(localStorage.getItem("timeZone"))
          .format("YYYY/MM/DD")
      );
    },
  },
  mounted() {
    // this.initTimeColumns();
  },
  watch: {
    showTime(newVal) {
      if (newVal) {
        this.initTimeColumns()
      }
    },
    showToTime(newVal) {
      if (newVal) {
        this.initTimeColumns()
      }
    }

  },
  methods: {
    formatDate(value) {
      if (!value) return;
      if (value === "Today") return "Today";
      return moment
        .tz(value, localStorage.getItem("timeZone"))
        .format("MM/DD/YYYY");
    },
    init(selectFood, dataForm = null) {
      console.log(selectFood);
      console.log(dataForm);

      this.selectFood = selectFood;
      if (dataForm) {
        let now = moment()
          .tz(localStorage.getItem("timeZone"))
          .format("YYYY-MM-DD");
        console.log(now);

        this.dataForm = dataForm;
        if (this.dataForm.fromDate === now) {
          this.dataForm.fromDate = "Today";
        } else {
          this.dataForm.fromDate = this.formatDate2(this.dataForm.fromDate)
        }
        if (this.dataForm.toDate === now) {
          this.dataForm.toDate = "Today";
        } else {
          this.dataForm.toDate = this.formatDate2(this.dataForm.toDate)
          console.log(this.dataForm.toDate);

        }
        this.isEdit = true;
      }
      if (this.isEdit) {
        this.initTimeColumns()
      } else {
        this.initToTime(this.selectFood)

      }
      this.showPopup = true;
    },
    formatDate2(inputDate) {
      // 创建一个日期对象
      let date = moment(inputDate)
        .tz(localStorage.getItem("timeZone"));
      
      // 获取星期名称的数组
      var daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      // 获取月份名称的数组
      var monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      // 提取日期的部分
      var dayOfWeek = daysOfWeek[date.day()];
      var month = monthsOfYear[date.month()];
      var dayOfMonth = date.date();

      // 返回格式化的日期字符串
      return dayOfWeek + ' ' + month + ' ' + dayOfMonth;
    },
    convertToArr(timeStr) {
      // 使用冒号作为分隔符分割时间
      const parts = timeStr.split(':');

      // 提取小时和分钟
      const hourPart = parts[0].trim();
      const minutePart = parts[1].trim(); // 去除可能的空格

      // 提取AM/PM标识
      const period = minutePart.slice(-2).toUpperCase();
      const minuteOnly = minutePart.slice(0, -2).trim(); // 去除AM/PM后的分钟部分

      // 返回结果数组
      return [hourPart, minuteOnly, period];
    },
    initToTime(selectFood) {
      let { chargeType, chargeUnit, midNight } = selectFood;
      // 当ChargeType = hour时，To时间自动显示为当前时间加上chargeUnit后的时间
      //假设当前时间为 10 /07 09: 15 AM, chargeType = hour and chargeUnit = 4
      // From = Today    Now
      // To = Today    01: 15 PM
      //   (09: 15 AM + 4小时 = 01: 15 PM)
      this.dataForm.toDate = "";
      this.dataForm.toTime = "";
      if (chargeType === "hour") {
        this.dataForm.toDate = "Today";
        // 返回格式化的字符串
        let toTimeArr = this.timeInNHours(chargeUnit);
        this.dataForm.toTime = toTimeArr[0] + ":" + toTimeArr[1] + " " + toTimeArr[2];
        if (!toTimeArr[3]) {
          this.dataForm.toDate = this.getNextDate()
        }
      } else if (chargeType === "day") {

        this.dataForm.toDate = "Today";
        this.dataForm.toTime = this.timeOneSecondBefore(midNight)
      }
      this.initTimeColumns()
    },
    timeInNHours(n) {

      // 创建一个新的Date对象用于当前时间
      var now = new Date(moment()
        .tz(localStorage.getItem("timeZone"))
        .format("YYYY-MM-DD HH:mm:ss"));
      // 创建一个新的Date对象表示n小时后的时间
      // 1小时 = 60分钟 * 60秒 * 1000毫秒
      var hoursInMs = n * 60 * 60 * 1000;
      var future = new Date(now.getTime() + hoursInMs);
      // 获取小时和分钟
      var hours = future.getHours();
      var minutes = future.getMinutes();
      // 确定是AM还是PM
      var period = (hours >= 12) ? "PM" : "AM";
      // 将小时转换为12小时制
      hours = hours % 12 || 12; // 当hours为0时，应显示为12
      // 对小时和分钟进行格式化，如果它们是一位数，则前面加0
      hours = ('0' + hours).slice(-2);
      minutes = ('0' + minutes).slice(-2);
      // 返回格式化的字符串
      return [hours, minutes, period, now.getDay() == future.getDay()];
    },
    timeOneSecondBefore(timeStr) {
      // 定义一个函数来解析时间字符串成 Date 对象
      function parseTime(str) {
        const matches = str.match(/(\d{1,2}):(\d{2})\s?(?:AM|PM)/i);
        if (!matches) throw new Error('Invalid time format');

        let [_, hourStr, minuteStr, period] = matches;
        let hour = parseInt(hourStr, 10);
        let periodMultiplier = (period === 'PM') ? 12 : 0;

        // 如果是中午12点，则不需要加上12
        if (hour === 12 && periodMultiplier === 0) {
          periodMultiplier = -12;
        }

        hour += periodMultiplier;
        let minute = parseInt(minuteStr, 10);

        return new Date(0, 0, 0, hour, minute, 0);
      }

      // 定义一个函数来格式化 Date 对象为时间字符串
      function formatTime(date) {
        let hour = date.getHours();
        let minute = date.getMinutes();
        let period = hour >= 12 ? 'PM' : 'AM';
        hour %= 12;
        hour = hour ? hour : 12; // the hour '0' should be '12'
        minute = ('0' + minute).slice(-2); // pad with zero if necessary
        return `${hour}:${minute} ${period}`;
      }

      // 解析时间字符串
      let parsedTime = parseTime(timeStr);

      // 减去一秒钟
      parsedTime.setSeconds(parsedTime.getSeconds() - 1);

      // 返回格式化后的时间字符串
      return formatTime(parsedTime);
    },
    getNextDate() {
      // 今天
      let now = new Date(moment()
        .tz(localStorage.getItem("timeZone"))
        .format("YYYY-MM-DD HH:mm:ss"));
      let tomorrow = new Date(now.setDate(now.getDate() + 1));
      return this.getWeekMonthDay(tomorrow.getDay(), tomorrow.getMonth(), tomorrow.getDate())
    },
    getWeekMonthDay(week, month, day) {
      // 定义星期和月份的名称数组
      const weekNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return `${weekNames[week]} ${monthNames[month]} ${day}`
    },
    // 输出星期/月/日
    generateDatesArray(startDate, monthsAhead) {
      // 创建一个日期数组
      let datesArray = [];

      // 创建起始日期
      let currentDate = new Date(startDate);

      // 今天
      let now = new Date(moment()
        .tz(localStorage.getItem("timeZone"))
        .format("YYYY-MM-DD HH:mm:ss"));

      // 计算结束日期，今天加上6个月
      const endDate = new Date(moment()
        .tz(localStorage.getItem("timeZone"))
        .format("YYYY-MM-DD HH:mm:ss"));
      endDate.setMonth(endDate.getMonth() + monthsAhead);

      // 循环添加日期到数组中，直到达到结束日期
      // 定义星期和月份的名称数组
      const weekNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      // 获取当前的星期几、月份和日期
      let week_today = weekNames[now.getDay()];
      let month_today = monthNames[now.getMonth()];
      let day_today = now.getDate();

      while (currentDate <= endDate) {

        // 获取当前日期的星期几、月份和日期
        let week = weekNames[currentDate.getDay()];
        let month = monthNames[currentDate.getMonth()];
        let day = currentDate.getDate();
        let year = currentDate.getFullYear()

        // 时间戳列表
        this.timeStamp.push(`${year}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + day).slice(-2)}`)

        // 将日期添加到数组中
        if (week_today === week && month_today === month && day_today === day) {
          datesArray.push("Today")
        } else {
          datesArray.push(`${week} ${month} ${day}`);

        }
        // 增加一天
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return datesArray;
    },
    initTimeColumns() {
      this.fromTimeColumns = [];
      this.toTimeColumns = [];
      // 初始化时间控件
      // 格式：星期/月/日 + 小时 + 分钟 + AM/PM
      const startDate = '2024-01-01';
      const monthsAhead = 6;
      const dates = this.generateDatesArray(startDate, monthsAhead);

      let nowArr = this.timeInNHours(0)
      if (this.isEdit) {
        nowArr = this.splitTimeSimple(this.dataForm.fromTime)
      }
      let toTimeArr = null;
      if (this.dataForm.toTime != "") {
        toTimeArr = this.convertToArr(this.dataForm.toTime);
      } else {
        toTimeArr = nowArr;
      }
      // 默认日期
      this.fromTimeColumns.push({
        values: dates,
        defaultIndex: dates.findIndex((e) => e === this.dataForm.fromDate),
      });
      this.toTimeColumns.push({
        values: dates,
        defaultIndex: dates.findIndex((e) => e === (this.dataForm.toDate != "" ? this.dataForm.toDate : "Today")),
      });
      let hour = [];
      for (let i = 1; i <= 12; i++) {
        if (i < 10) {
          hour.push("0" + i);
        } else {
          hour.push("" + i);
        }
      }
      this.fromTimeColumns.push({
        values: hour,
        defaultIndex: hour.findIndex((e) => e === nowArr[0])
      });
      this.toTimeColumns.push({
        values: hour,
        defaultIndex: hour.findIndex((e) => e === toTimeArr[0]),
      });
      let minte = [];
      for (let i = 0; i <= 59; i++) {
        if (i < 10) {
          minte.push("0" + i);
        } else {
          minte.push("" + i);
        }
      }
      this.fromTimeColumns.push({
        values: minte,
        defaultIndex: minte.findIndex((e) => e === nowArr[1])
      });
      this.toTimeColumns.push({
        values: minte,
        defaultIndex: minte.findIndex((e) => e === toTimeArr[1])
      });
      this.fromTimeColumns.push({
        values: ["AM", "PM"],
        defaultIndex: ["AM", "PM"].findIndex((e) => e === nowArr[2])
      });
      this.toTimeColumns.push({
        values: ["AM", "PM"],
        defaultIndex: ["AM", "PM"].findIndex((e) => e === toTimeArr[2])
      });
    },
    splitTimeSimple(timeString) {
      var parts = timeString.split(':');
      var timeArray = parts[0].split(' '), // 假设没有中间空格，这一步可以省略
        minutePart = parts[1].trim().split(' ');

      return [timeArray[0], minutePart[0], minutePart[1]];
    },
    close() {
      this.showPopup = false;
    },
    dateConfirm(date) {
      this.dataForm[this.chooseDateType] = moment(date.getTime()).format(
        "YYYY-MM-DD"
      );
      if (
        this.chooseDateType === "fromDate" &&
        (this.dataForm.fromTime === "" || this.dataForm.fromTime === "Now")
      ) {
        this.dataForm.fromTime = "10:00 AM";
      }
      if (this.chooseDateType === "toDate" && this.dataForm.toTime === "") {
        this.dataForm.toTime = "10:00 AM";
      }
      this.showDate = false;
    },
    timeConfirm(value) {
      if (this.chooseTimeType === "fromTime") {
        this.dataForm.fromDate = value[0];
        this.dataForm.fromTime =
          value[1] + ":" + value[2] + " " + value[3];
        this.showTime = false;
      } else if (this.chooseTimeType === "toTime") {
        this.dataForm.toDate = value[0];
        this.dataForm.toTime =
          value[1] + ":" + value[2] + " " + value[3];
        this.showToTime = false;
      }
    },
    chooseDate(type) {
      this.chooseDateType = type;
      this.showDate = true;
    },
    chooseTime(type) {
      this.chooseTimeType = type;
      if (type === "fromTime") {
        this.showTime = true;
      } else {
        this.showToTime = true;
      }
    },
    async submit() {
      if (!this.dataForm.toDate) {
        Toast("Please select To date");
        return;
      }
      const { chargeType, chargeUnit, midNight } = this.selectFood;
      let { fromDate, fromTime, toDate, toTime } = this.dataForm;

      let currentDateTime = moment()
        .tz(localStorage.getItem("timeZone"))
        .format("YYYY-MM-DD HH:mm:ss"); // 酒店时区
      console.log("currentDateTime==============", currentDateTime);

      fromDate = this.timeStamp[this.fromTimeColumns[0].values.findIndex((e) => e === fromDate)]
      toDate = this.timeStamp[this.fromTimeColumns[0].values.findIndex((e) => e === toDate)]


      // if (fromDate === "Today") {
      //   fromDate = moment
      //     .tz(currentDateTime, localStorage.getItem("timeZone"))
      //     .format("YYYY-MM-DD"); // 酒店日期
      // }
      if (fromTime === "Now") {
        fromTime = moment
          .tz(currentDateTime, localStorage.getItem("timeZone"))
          .format("hh:mm A");
      }
      // if (toDate === "Today") {
      //   toDate = moment
      //     .tz(currentDateTime, localStorage.getItem("timeZone"))
      //     .format("YYYY-MM-DD"); // 酒店日期
      // }
      console.log("fromDate==============", fromDate);
      console.log("fromTime==============", fromTime);
      console.log("toDate==============", toDate);
      console.log("toTime==============", toTime);
      let fromMs = moment
        .tz(
          fromDate + " " + moment(fromTime, "h:m A").format("HH:mm:ss"),
          localStorage.getItem("timeZone")
        )
        .valueOf();
      console.log(fromDate + " " + moment(fromTime, "h:m A").format("HH:mm:ss"));

      console.log("fromMs=============", fromMs);
      let toMs = moment
        .tz(
          toDate +
          " " +
          moment(toTime, "h:m A").format("HH:mm:ss"),
          localStorage.getItem("timeZone")
        )
        .valueOf();
      console.log("toMs=============", toMs);
      let now = moment
        .tz(currentDateTime, localStorage.getItem("timeZone"))
        .valueOf();
      console.log("now=============", now);
      if (fromMs > toMs) {
        Toast("The to time cannot be earlier than the from time.");
        return;
      }
      if (fromMs > now) {
        Toast("The from time cannot be later than the current time.");
        return;
      }
      const res = await postAction(
        "/adminApi/parking/getParkingTime",
        Object.assign({}, this.dataForm, {
          chargeType,
          chargeUnit,
          midNight,
          fromDate,
          fromTime,
          toDate
        })
      );
      if (res.status !== "0") {
        Toast(res.msg);
        return;
      }
      this.$emit("callback", {
        num: Number(res.data),
        dataForm: Object.assign({}, this.dataForm, {
          fromDate,
          fromTime,
          toDate,
          isEdit: this.isEdit,
        }),
      });
      // this.$emit("callback", {
      //   num: 4,
      //   dataForm: Object.assign({}, this.dataForm, {
      //     fromDate,
      //     fromTime,
      //     isEdit: this.isEdit,
      //   }),
      // });
      this.showPopup = false;
    },
    fromTimePikerCancel() {
      this.showTime = false;
    },
    toTimePikerCancel() {
      this.showToTime = false;
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/.van-calendar__popup.van-popup--bottom {
  height: 70%;
}

.header {
  height: auto;
  padding: 20px;
  border-bottom: 1px solid #eee;


  div:first-of-type {
    white-space: normal;
    padding-right: 20px;
    color: #000;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }
}

.hint {
  font-size: 20px;
  color: #282828;
  margin-top: 20px;
}

.from {
  margin-top: 30px;

  .label {
    width: 50px;
    color: #000;
    font-size: 20px;
    text-align: left;
  }

  .inputBox {
    width: 220px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #a4a4a4;
    position: relative;

    .icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    .value {
      font-size: 15px;
      color: #000;
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 86%;
      left: -10px;
    }

    .noValue {
      font-size: 15px;
      color: #000;
    }
  }
}

.btn-group {
  margin-top: 30px;
  margin-bottom: 26pt;

  .btn-skip {
    background: $primary-color;
    width: 84%;
    color: #ffffff;
    font-size: 13pt;
    border-radius: 4px;
  }
}


/deep/ .van-picker__cancel {
  color: #1989fa;
  font-size: 16px;
  font-weight: bold;
}

/deep/ .van-picker__confirm {
  color: #1989fa;
  font-size: 16px;
  font-weight: bold;
}

/deep/ .van-picker__columns {
  padding: 0px 40px;

  .van-picker-column {
    flex: auto;
  }
}

/deep/ .van-picker__frame {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
